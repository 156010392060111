import { useEffect } from "react";
import closeIcon from '~/assets/close.svg'
import { ValueOrFunction, resolveValue } from "~/utils/handy";

type Props = {
    title?: string;
    children: ValueOrFunction<React.ReactNode,() => void>;
    requestToClose: () => void
}

const ModalWrapperView = ({ title, children, requestToClose }: Props) => {

    useEffect(() => {
      const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
          requestToClose();
        }
      };
    
      document.addEventListener('keydown', handleKeyDown);
    
      return () => {
        document.removeEventListener('keydown', handleKeyDown);
      };
    }, []);

    return <>
    <div
      className="justify-center items-center flex overflow-x-hidden overflow-y-auto overflow-style-none fixed inset-0 z-50 outline-none focus:outline-none max-h-full"
    >
      <div className="relative w-auto my-6 mx-auto max-w-3xl">
        <div role="dialog" className="border-0 rounded-lg overflow-hidden shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className="flex flex-row z-30 items-center w-full relative py-3">
            {title && <p className="font-semibold text-center self-center mx-auto text-base">{title}</p>}
            <button
              className="absolute right-1 top-1 p-1 self-end bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
              onClick={requestToClose}
            >
              <span className="bg-transparent text-black h-6 w-6 text-2xl outline-none focus:outline-none justify-center items-center flex">
                <img src={closeIcon} alt="close-icon" className="w-4 h-4" />
              </span>
            </button>
          </div>
          {resolveValue(children, requestToClose)}
        </div>
      </div>
    </div>
    <div className="opacity-25 fixed inset-0 z-40 bg-black" />
  </>
}
export default ModalWrapperView